import showdown from 'showdown'
import DOMPurify from 'dompurify'

export const markdownMixin = {
  data() {
    return {
      converter: new showdown.Converter({
        tables: true,
        parseImgDimensions: true,
        simplifiedAutoLink: true,
        literalMidWordUnderscores: true,
        strikethrough: true,
        ghCodeBlocks: true,
        taskLists: true,
        smoothLivePreview: true,
        ghCompatibleHeaderId: true,
        encodeEmails: true,
        ellipsis: true,
        smartIndentationFix: true,
        headerLevelStart: 3,
      }),
    }
  },
  methods: {
    formatMarkdownToHtml(value) {
      this.converter.setFlavor('github')

      return this._convertAndSanitize(value)
    },

    // private methods

    _convertAndSanitize(value) {
      if (typeof value !== 'string') {
        value = String(value)
      }

      let htmlContent = this.converter.makeHtml(value)
      htmlContent = DOMPurify.sanitize(htmlContent)

      return htmlContent
    },
  },
}
