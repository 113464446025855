<template>
  <div class="public-api-documentation-page">
    <h4 class="title">
      Corporate Tools API
    </h4>
    <div class="public-api-documentation">
      <public-api-docs-side-bar :bus="bus" />
      <b-container class="documentation-container">
        <div
          v-if="!loading"
          class="documentation"
          v-html="markdownStringToHtml"
        />
        <ct-centered-spinner v-else />
      </b-container>
    </div>
  </div>
</template>

<script>
import { markdownMixin } from '@/mixins/markdownMixin.js'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'

export default {
  name: 'PublicApiDocumentation',
  components: {
    PublicApiDocsSideBar: () => import('@/pages/APIManagement/Documentation/PublicApiDocsSideBar.vue'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  mixins: [
    markdownMixin,
    makeToastMixin,
  ],
  data() {
    return {
      bus: new Vue(),
      loading: false,
      markdown: '',
    }
  },
  computed: {
    ...mapGetters('publicApiDocumentation', [
      'apiDocsMeta',
      'docsAdminToolsVisible',
    ]),
    markdownStringToHtml() {
      return this.formatMarkdownToHtml(this.markdown)
    },
  },
  async mounted() {
    this.bus.$on('navigate-documentation', this.updateMarkdownToDisplay)
  },
  methods: {
    ...mapActions('publicApiDocumentation', [
      'fetchApiDocsContentById',
    ]),
    async updateMarkdownToDisplay(documentationId) {
      this.loading = true

      try {
        this.markdown = await this.fetchApiDocsContentById(documentationId)
      } catch(_error) {
        this.errorToast("Error", "Failed to load documentation")
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../api-management-shared-styles';

.public-api-documentation-page {
  width: 100%;

  .title {
    width: 100%;
    border: none;
    padding-bottom: 1em;
    margin-bottom: 0;
  }

  .public-api-documentation {
    display: flex;
    flex-direction: row;
    height: 100%;
    border: 1px solid $ct-ui-color-22;

    .documentation-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      padding: 2em;

      .documentation {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .public-api-documentation {
      flex-direction: column;
    }
  }
}
</style>

