var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "public-api-documentation-page" }, [
    _c("h4", { staticClass: "title" }, [
      _vm._v("\n    Corporate Tools API\n  "),
    ]),
    _c(
      "div",
      { staticClass: "public-api-documentation" },
      [
        _c("public-api-docs-side-bar", { attrs: { bus: _vm.bus } }),
        _c(
          "b-container",
          { staticClass: "documentation-container" },
          [
            !_vm.loading
              ? _c("div", {
                  staticClass: "documentation",
                  domProps: { innerHTML: _vm._s(_vm.markdownStringToHtml) },
                })
              : _c("ct-centered-spinner"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }